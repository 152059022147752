<template>
  <div>
    <Swiper
      class="w-full below-pagination"
      grab-cursor
      :modules="modules"
      update-on-window-resize
      :pagination="{
        clickable: true,
        dynamicBullets: true,
        dynamicMainBullets: 10,
      }"
      :autoplay="{
        delay: 4000,
      }"
      @swiper="init"
      ref="galleryEl"
    >
      <swiper-slide v-for="(img, idx) of pictures" :key="img.data || idx">
        <a
          class="w-full rounded overflow-hidden flex items-center justify-center cursor-pointer hover:opacity-90 scale-bg-hover transition-all duration-200 gallery-image-container"
          :data-pswp-width="img.width"
          :data-pswp-height="img.height"
          :href="img?.attributes ? img.attributes?.url : img?.url"
          :data-cropped="true"
          target="_blank"
          :style="{
            'aspect-ratio': props.aspectRatio,
            /*'background-image':
              'url(' +(img?.attributes
                ? img.attributes.formats?.small?.url || img.attributes?.url
                : img?.formats?.small?.url || img?.url)
               +
              ')',*/
          }"
        >
          <img
            :src="
              img?.attributes
                ? img.attributes.formats?.small?.url || img.attributes?.url
                : img?.formats?.small?.url || img?.url
            "
            :style="{
              'min-width': '100%',
              'min-height': '100%',
              'max-width': 'none',
              'max-height': 'none',
            }"
          />
        </a>
      </swiper-slide>
    </Swiper>
  </div>
</template>
<script setup>
import { ref, watch, onMounted } from "vue";
import AquariumHeader from "@/components/aquarium/AquariumHeader.vue";
import "swiper/css";
import "swiper/css/pagination";

import "@ionic/vue/css/ionic-swiper.css";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, A11y, Autoplay } from "swiper";

import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";

const props = defineProps({
  pictures: Array,
  label: String,
  subtitle: String,
  imageId: String,
  portal: Boolean,
  aspectRatio: {
    type: Number,
    default: 16 / 9,
  },
});

const isOpen = ref(false);

const swiper = ref();
const modules = [Pagination, A11y, Autoplay];
const galleryEl = ref();

function init(instance) {
  swiper.value = instance;
  if (currentToIdx.value && instance) {
    instance.slideTo(currentToIdx.value);
  }
}
const currentToIdx = ref(null);
watch(
  () => [props.imageId, props.pictures],
  ([n, pics]) => {
    const idx = pics.findIndex((p) => p.id == n);
    if (idx > -1) {
      currentToIdx.value = idx;
      if (swiper.value) {
        swiper.value.slideTo(currentToIdx.value);
      }
    }
  },
  { immediate: true }
);

const lightbox = ref();
onMounted(() => {
  if (galleryEl.value) {
    const options = {
      gallery: galleryEl.value.$el,
      children: "a.gallery-image-container",
      pswpModule: () => import("photoswipe"),
      showHideAnimationType: "none",
      appendToEl: document.querySelector(".ion-page"),
    };
    Object.assign(options, {
      showHideAnimationType: "none",
      zoomAnimationDuration: false,
      showAnimationDuration: 0,
      hideAnimationDuration: 0,
    });
    lightbox.value = new PhotoSwipeLightbox(options);
    lightbox.value.addFilter(
      "useContentPlaceholder",
      (useContentPlaceholder, content) => {
        return false;
      }
    );
    /*lightbox.value.addFilter("placeholderSrc", (placeholderSrc, content) => {
      return;
    });*/
    lightbox.value.init();
  }
});
</script>

<style lang="css" scoped>
.gallery-item {
  display: block;
}

.gallery-item img {
  /*display: inline-block;*/
}
</style>
<style>
.pswp__top-bar {
  top: 40px !important;
}
.pswp img {
  object-fit: contain;
}
/*.pswp__img--placeholder {
  position: absolute !important;
  width: 100vw;
  top: 50vh !important;
  transform: translateY(-50vh) !important;
}*/
</style>
