import { defineStore } from "pinia";
import { useStrapi } from "@/composable/useStrapi";
import { useNotifications } from "@/store/useNotifications.js";
import { useSavePictures } from "@/composable/useSavePictures";

const PAGE_SIZE = 60;
const PAGE_MESSAGE_SIZE = 100;

const FIELDS_MAPPING = {
  default: {
    list: "conversations",
    mayHasMore: "mayHasMore",
    params: "params",
    pagination: "pagination",
    route: `conversations`,
  },
  ads: {
    list: "conversationsForAds",
    mayHasMore: "mayHasMoreAds",
    params: "adsParams",
    pagination: "adsPagination",
    route: `conversations`,
  },
  ad: {
    list: "conversationsForAd",
    mayHasMore: "mayHasMoreAd",
    params: "adParams",
    pagination: "adPagination",
    route: `conversations`,
  },
};

export const useDms = defineStore("dm", {
  state: () => ({
    conversations: [], // list of groups of conversations
    mayHasMore: true,
    pagination: {
      pageSize: PAGE_SIZE,
      page: 1,
    },
    params: {},
    conversationsForAds: [],
    mayHasMoreAds: true,
    adsPagination: {
      pageSize: PAGE_SIZE,
      page: 1,
    },
    adsParams: { ads: true },
    conversationForAd: [],
    mayHasMoreAd: true,
    adPagination: {
      pageSize: PAGE_SIZE,
      page: 1,
    },
    adParams: { ads: true },

    messages: {},
    unread: [], // count unread messages
    shouldGo: null,
  }),
  getters: {
    unreadSum: (state) => {
      return state.unread?.reduce((acc, v) => acc + Number(v.unread), 0) || 0;
    },
    unreadDefSum: (state) => {
      return (
        state.unread
          ?.filter((v) => v.type == null)
          .reduce((acc, v) => acc + Number(v.unread), 0) || 0
      );
    },
    unreadAdsSum: (state) => {
      return (
        state.unread
          ?.filter((v) => v.type == "ad")
          .reduce((acc, v) => acc + Number(v.unread), 0) || 0
      );
    },
  },
  actions: {
    /** Conversations with pagination */
    async search(key = null) {
      const def = FIELDS_MAPPING[key || "default"];
      this[def.mayHasMore] = true;
      this[def.pagination] = {
        pageSize: PAGE_SIZE,
        page: 1,
      };
      this[def.list] = await this.findConversations(def);
    },
    async findConversations(def, pagination, additionalFilters) {
      const { find, user } = useStrapi();
      const res = await find(def.route, {
        pagination: pagination || this[def.pagination],
        populate: ["users", "document"],
        filters: {
          ...this[def.params],
          ...(additionalFilters || {}),
          users: user.value?.id,
        },
        sort: { last_date: "desc" },
      });
      if (
        !pagination &&
        res &&
        res.data &&
        res.data.length < this[def.pagination].pageSize
      ) {
        this[def.mayHasMore] = false;
      }
      return (
        res?.data?.map((n) => {
          const rconv = { ...(n.attributes || {}), id: n.id };
          // there can be an ad linked to the conversation if params contains ads: true, sort medias of ad in this case
          if (rconv.ad) {
            const { sortMedias } = useSavePictures();
            sortMedias(rconv.ad, "medias", rconv.ad.details?.medias_order);
          }
          /** Cerfa or document signed */
          if (rconv.document && rconv.document.data) {
            rconv.document = {
              ...(rconv.document.data.attributes || {}),
              id: rconv.document.data.id,
            };
          }
          return rconv;
        }) || []
      );
    },
    async more(key = null) {
      const def = FIELDS_MAPPING[key || "default"];
      this[def.pagination].page++;
      const nextEntries = await this.findConversations(def);
      this[def.list].push(...nextEntries);
    },

    // load a conversation if not loaded
    async findConversation(id, force) {
      const foundIn = [],
        idx = [];
      Object.keys(FIELDS_MAPPING).forEach((k) => {
        const foundConvIdx =
          this[FIELDS_MAPPING[k].list] &&
          this[FIELDS_MAPPING[k].list].findIndex((conv) => conv.id == id);
        if (foundConvIdx > -1) {
          foundIn.push(FIELDS_MAPPING[k].list);
          idx.push(foundConvIdx);
        }
      });
      if (idx.length > 0 && idx[0] > -1 && !force) {
        return this[foundIn[0]][idx[0]];
      } else {
        const { find } = useStrapi();
        const res = await find(`conversations`, {
          filters: { id },
          populate: ["users", "document"],
        });
        const resFormatted =
          res?.data?.map((n) => {
            const r = { ...(n.attributes || {}), id: n.id };
            /** Cerfa or document signed */
            if (r.document && r.document.data) {
              r.document = {
                ...(r.document.data.attributes || {}),
                id: r.document.data.id,
              };
            }
            return r;
          }) || null;

        if (idx.length > 0) {
          idx.forEach((val, pos) => {
            const oldad = this[foundIn[pos]][val].ad;
            // update conversation in list
            this[foundIn[pos]][val] = resFormatted && resFormatted[0];
            // keep ad retrieved from list on conversation if it exist
            if (oldad != null) {
              this[foundIn[pos]][val].ad = oldad;
            }
          });
        }
        return resFormatted && resFormatted[0];
      }
    },

    /** Find a conversation with another user */
    async findDM(withUserId, type, ref_id) {
      const { client } = useStrapi();
      const res = await client(
        `/conversation/find-dm-with/${withUserId}${
          type ? "/" + type + "/" + ref_id : ""
        }`
      );
      return res;
    },

    /** Total unread count */
    async count() {
      // refresh unread messages
      const { client, user } = useStrapi();
      if (user.value) {
        const res = await client(`/conversation/unread`);
        this.unread = res || [];
      }
    },

    async markNotificationsAsRead(id) {
      // mark all notifications from this conversation as read
      const notifications = useNotifications();
      await notifications.readTypeRef("DM", id);
      const unreadLine = this.unread?.find((unr) => unr.conversation_id == id);
      if (unreadLine) {
        unreadLine.unread = 0;
      }
    },

    setLastMessage(id) {
      // set last message on conversation / last_date
      if (this.messages[id].list?.length > 0) {
        let foundConv = this.conversations.find((conv) => conv.id == id);
        let ads = false;
        if (foundConv == null) {
          foundConv = this.conversationsForAds.find((conv) => conv.id == id);
          if (foundConv) {
            ads = true;
          }
        }
        if (foundConv) {
          foundConv.last_message = this.messages[id].list[0].message;
          foundConv.last_date = this.messages[id].list[0].createdAt;
        }
        if (ads) {
          this.conversationsForAds.sort(
            (a, b) =>
              ((b.last_date &&
                typeof b.last_date.getTime === "function" &&
                b.last_date.getTime()) ||
                0) -
              ((a.last_date &&
                typeof a.last_date.getTime === "function" &&
                a.last_date.getTime()) ||
                0)
          );
        } else {
          this.conversations.sort(
            (a, b) =>
              ((b.last_date &&
                typeof b.last_date.getTime === "function" &&
                b.last_date.getTime()) ||
                0) -
              ((a.last_date &&
                typeof a.last_date.getTime === "function" &&
                a.last_date.getTime()) ||
                0)
          );
        }
      }
    },

    /** Messages with pagination for each conversation */
    async searchMessages(id) {
      if (!this.messages[id]) {
        this.messages[id] = {
          unread: 0,
          list: [],
          pagination: {
            pageSize: PAGE_MESSAGE_SIZE,
            page: 1,
          },
          mayHasMore: true,
        };
      }
      const messData = await this.findMessages(id);
      this.messages[id].list = messData.entries;
      await this.markNotificationsAsRead(id);
      this.setLastMessage(id);
    },
    async findMessages(id, additionalFilters) {
      // when native notif is tapped, we have its id, find it
      const { find } = useStrapi();
      const res = await find(`conversation-messages`, {
        filters: { conversation: id, ...(additionalFilters || {}) },
        populate: ["user", "medias"],
        pagination: this.messages[id].pagination,
        sort: { createdAt: "desc" },
      });
      if (
        res &&
        res.data &&
        res.data.length < this.messages[id].pagination.pageSize
      ) {
        this.messages[id].mayHasMore = false;
      }
      return {
        entries:
          res?.data?.map((n) => {
            return { ...(n.attributes || {}), id: n.id };
          }) || [],
        infos: res?.infos,
      };
    },
    async refreshLatestMessages(id) {
      if (
        this.messages[id] &&
        this.messages[id].pagination &&
        this.messages[id].list &&
        this.messages[id].list.length > 0
      ) {
        const messData = await this.findMessages(id, {
          createdAt: { $gt: this.messages[id].list[0].createdAt },
        });
        const newEntries = messData.entries;
        if (newEntries.length > 0) {
          this.messages[id].list.unshift(...newEntries);
        }
        // check message infos (remove old if doubles), add new if new on old messages
        // messData.infos -> messages lus par user -> user_id, message_id, message_date, created_at
        // messData.infos contains for each participant, the last message they have seen, it is present even if no message are returned
        messData.infos.forEach((inf) => {
          this.messages[id].list.forEach((mess) => {
            if (mess.id == inf.message_id) {
              // add information
              if (!mess.infos) mess.infos = [];
              const found = mess.infos.find((mi) => mi.user_id == inf.user_id);
              if (!found) {
                mess.infos.push(inf);
              }
            } else {
              const idx = mess.infos?.findIndex(
                (mi) => mi.user_id == inf.user_id
              );
              if (idx > -1) {
                // remove info
                mess.infos.splice(idx, 1);
              }
            }
          });
        });
        await this.markNotificationsAsRead(id);
        this.setLastMessage(id);
        return {
          done: true,
          hasSpecial: newEntries.find((m) => m.special_type != null) != null,
        };
      } else {
        this.searchMessages(id);
      }
    },
    async moreMessages(id) {
      if (this.messages[id] && this.messages[id].pagination) {
        this.messages[id].pagination.page++;
        const messData = await this.findMessages(id);
        const nextEntries = messData.entries;
        this.messages[id].list.push(...nextEntries);
      }
    },

    /** Create a conversation */
    async createConversation(withId, type, ref_id) {
      try {
        const { create } = useStrapi();
        const res = await create("conversations", {
          data: {
            withId,
            type,
            ref_id,
          },
        });
        await this.search();
        return res;
      } catch (e) {
        console.log(e);
        return null;
      }
    },

    /** create a message in a conversation */
    async createMessage(conversationId, data, pictures) {
      const { create, fetchUser } = useStrapi();
      try {
        const toCreate = {
          conversation: conversationId,
          ...data,
        };
        if (pictures && pictures.length > 0) {
          toCreate.hasPhotosNext = true;
        }
        const res = await create("conversation-messages", {
          data: toCreate,
        });
        // create pictures
        if (pictures && pictures.length > 0) {
          const { saveMedias } = useSavePictures();
          const { medias_order } = await saveMedias(
            "conversation-messages",
            "medias",
            res.data.id,
            pictures,
            null,
            { multiple: true }
          );
        }
        await this.refreshLatestMessages(conversationId);
        return res;
      } catch (e) {
        console.log(e, e.response?.data?.error?.message);
        if (e.response?.data?.error?.message === "blocked") {
          await fetchUser(true);
        }
        return null;
      }
    },
  },
});
