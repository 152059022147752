import { ref } from "vue";
import { LOG_LEVEL, Purchases } from "@revenuecat/purchases-capacitor";

import { isPlatform } from "@ionic/vue";
import dayjs from "dayjs";
import { useStrapi } from "./useStrapi";
import { useSubscribe } from "./useSubscribe";
import { Capacitor } from "@capacitor/core";

const RCAT_GOOGLE_API_KEY =
  process.env.RCAT_GOOGLE_API_KEY || "goog_daHtrwaTPbUpHJNLOYoazSjUnGc";
const RCAT_APPLE_API_KEY =
  process.env.RCAT_APPLE_API_KEY || "appl_RBFbZWNYMEtzeTbjMIKJlyNGxHQ";

const customerInfo = ref();
const offerings = ref();
const no_rcat = ref(false);
const initialized = ref(false);
export const useRcat = () => {
  const initRcat = async () => {
    if (initialized.value) return;
    if (Capacitor.isNativePlatform()) {
      try {
        const { user, setNotificationToken } = useStrapi();
        // Initialise Rcat
        await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG }); // Enable to get debug logs
        await Purchases.configure({
          apiKey:
            Capacitor.getPlatform() === "ios"
              ? RCAT_APPLE_API_KEY
              : Capacitor.getPlatform() === "android"
              ? RCAT_GOOGLE_API_KEY
              : null,
          appUserID:
            user.value && user.value.id ? "user_" + user.value.id : null,
        });
        // Get all user customerInfo (contains entitlements)
        const customerInfoResult = await Purchases.getCustomerInfo();
        customerInfo.value = customerInfoResult.customerInfo;
        setNotificationToken();

        // Get all offerings (products)
        offerings.value = await Purchases.getOfferings();
        initialized.value = true;

        /*const purchasListenerId = await Purchases.addCustomerInfoUpdateListener((cusInfo) => {
          // handle any changes to customerInfo
          customerInfo.value = cusInfo;
          setNotificationToken();
          // if uncommented, think about calling removeCustomerInfoUpdateListener
        });*/
      } catch (e) {
        console.log("init error: ", e);
        // dev
        no_rcat.value = true;
        initialized.value = true;
      }
    } else {
      no_rcat.value = true;
      offerings.value = OFFERINGS;
      initialized.value = true;
    }
  };

  const purchase = async (aPackage) => {
    // TODO
    // keep only this to test purchase behavior locally
    /*const { setShouldSubscribe } = useSubscribe();
    setShouldSubscribe(false);
    return { purchased: true };*/

    try {
      const transaction = await Purchases.purchasePackage({
        aPackage,
      });
      if (transaction) {
        const isValid =
          transaction.customerInfo?.entitlements?.active?.premium?.isActive ||
          false;
        // check entitlements
        if (isValid) {
          // save user has bought a subscription
          const { client, user } = useStrapi();
          const toUpdate = {};
          if (isPlatform("ios")) {
            toUpdate.sub_ios = true;
          }
          if (isPlatform("android")) {
            toUpdate.sub_android = true;
          }
          await client(`/users/${user.value?.id}`, {
            method: "PUT",
            data: { data: toUpdate },
          });
          // Reload user permissions
          customerInfo.value = transaction.customerInfo;
          const { setShouldSubscribe } = useSubscribe();
          setShouldSubscribe(false);
          return { purchased: true };
        }
      }
      return { purchased: false };
    } catch (e) {
      try {
        // Reload user permissions (in case purchase has been done, but error due to later action (ex : google asks for second payment method, click, refuse -> error but subscirbed))
        const customerInfoResult = await Purchases.getCustomerInfo();
        customerInfo.value = customerInfoResult.customerInfo;
        const isValid =
          customerInfo.value?.entitlements?.active?.premium?.isActive || false;
        if (isValid) {
          const { setShouldSubscribe } = useSubscribe();
          setShouldSubscribe(false);
          return { purchased: true };
        }
        return { purchased: false };
      } catch (e) {
        return { purchased: false };
      }
    }
  };

  const DEBUG_PRODUCT_LOCAL = true;
  const purchaseProduct = async (aPackage) => {
    try {
      if (DEBUG_PRODUCT_LOCAL && !Capacitor.isNativePlatform()) {
        return {
          purchased: true,
          transaction: {
            productIdentifier: aPackage.identifier,
            transaction: {
              transactionIdentifier: Math.random().toString(36).slice(2, 7),
              productIdentifier: aPackage.identifier,
              purchaseDateMillis: new Date().getTime(),
              purchaseDate: new Date().toString(),
            },
          },
        };
      }
      const transaction = await Purchases.purchasePackage({
        aPackage,
      });
      if (transaction) {
        return { purchased: true, transaction };
      }
      return { purchased: false };
    } catch (e) {
      console.error(e);
      return { purchased: false };
    }
  };

  const restore = async () => {
    try {
      const restorePurchasesResult = await Purchases.restorePurchases();
      customerInfo.value = restorePurchasesResult.customerInfo;
      const isValid =
        customerInfo.value?.entitlements?.active?.premium?.isActive || false;
      if (isValid) {
        const { setShouldSubscribe } = useSubscribe();
        setShouldSubscribe(false);
        return { restored: true };
      }
      return { restored: false };
    } catch (e) {
      return { restored: false };
    }
  };

  const syncPurchases = async () => {
    try {
      await Purchases.syncPurchases();
      const customerInfoResult = await Purchases.getCustomerInfo();
      customerInfo.value = customerInfoResult.customerInfo;
      const isValid =
        customerInfo.value?.entitlements?.active?.premium?.isActive || false;
      if (isValid) {
        const { setShouldSubscribe } = useSubscribe();
        setShouldSubscribe(false);
        return { sync: true };
      }
      return { sync: false };
    } catch (e) {
      return { sync: false };
    }
  };

  const checkSubscription = () => {
    // check internal unlimited permission
    const { user } = useStrapi();
    if (user.value?.unlimited) {
      // some users have unlimited access (set in backoffice)
      if (user.value.expire_unlimited) {
        // check expiration date of unlimited access
        if (dayjs().isBefore(dayjs(user.value.expire_unlimited))) {
          return true;
        }
      } else {
        return true;
      }
    }
    // check real permissions
    const isValid =
      customerInfo.value?.entitlements?.active?.premium?.isActive || false;
    return isValid;
  };

  const setDevice = async (token) => {
    return Purchases.setPushToken({ pushToken: token });
  };

  const connectUser = async () => {
    try {
      const { user } = useStrapi();
      if (user.value && user.value.id) {
        const loginResult = await Purchases.logIn({
          appUserID: "user_" + user.value.id,
        });
        customerInfo.value = loginResult.customerInfo;
        await Purchases.setDisplayName({ displayName: user.value.username });
        await Purchases.setEmail({ email: user.value.email });
      } else {
        await Purchases.logOut();
      }
    } catch (e) {
      console.error("Error while connecting user to rcat");
      console.error(e);
    }
  };

  return {
    offerings,
    customerInfo,
    no_rcat,
    connectUser,
    setDevice,
    checkSubscription,
    initRcat,
    purchase,
    restore,
    syncPurchases,
    purchaseProduct,
  };
};

/** OFFERINGS */

const OFFERINGS = {
  all: {
    "blue-ads-up": {
      identifier: "blue-ads-up",
      metadata: {
        up_classified_ad_3: {
          discount: 0.3311,
        },
      },
      availablePackages: [
        {
          identifier: "up_classified_ad_3",
          product: {
            identifier: "up_classified_ad_3:up_classified_ad_3",
            description: "Up ad to top for 3 days",
            title: "Up ad to top for 3 days",
            price: 0.99,
            priceString: "€0.99",
            currencyCode: "EUR",
          },
        },
        {
          identifier: "up_classified_ad_7",
          product: {
            identifier: "up_classified_ad_7:up_classified_ad_7",
            description: "Up ad to top for 7 days",
            title: "Up ad to top for 7 days",
            price: 4.99,
            priceString: "€4.99",
            currencyCode: "EUR",
          },
        },
        {
          identifier: "up_classified_ad_15",
          product: {
            identifier: "up_classified_ad_15:up_classified_ad_15",
            description: "Up ad to top for 15 days",
            title: "Up ad to top for 15 days",
            price: 7.99,
            priceString: "€7.99",
            currencyCode: "EUR",
          },
        },
      ],
    },
    "blue-ads-fav": {
      identifier: "blue-ads-fav",
      availablePackages: [
        {
          identifier: "fav_classified_ad_3",
          product: {
            identifier: "fav_classified_ad_3:fav_classified_ad_3",
            description: "Favorite for 3 days",
            title: "Favorite for 3 days",
            price: 2.99,
            priceString: "€2.99",
            currencyCode: "EUR",
          },
        },
        {
          identifier: "fav_classified_ad_7",
          product: {
            identifier: "fav_classified_ad_7:fav_classified_ad_7",
            description: "Favorite for 7 days",
            title: "Favorite for 7 days",
            price: 4.99,
            priceString: "€4.99",
            currencyCode: "EUR",
          },
        },
        {
          identifier: "fav_classified_ad_15",
          product: {
            identifier: "fav_classified_ad_15:fav_classified_ad_15",
            description: "Favorite for 15 days",
            title: "Favorite for 15 days",
            price: 7.99,
            priceString: "€7.99",
            currencyCode: "EUR",
          },
        },
      ],
    },
    "blue-subscription": {
      identifier: "blue-subscription",
      serverDescription: "Subscription to blue premium",
      metadata: {},
      availablePackages: [
        {
          identifier: "$rc_monthly",
          packageType: "MONTHLY",
          product: {
            identifier:
              "android_blue_subscription_month_7.99:android-blue-subscription-month-7-99",
            description: "Fully manage your aquarium with Blue",
            title: "Monthly subscription (Blue)",
            price: 4.99,
            priceString: "€4.99",
            currencyCode: "EUR",
            introPrice: null,
            discounts: null,
            pricePerWeek: 1148383,
            pricePerMonth: 4990000,
            pricePerYear: 59880000,
            pricePerWeekString: "€1.15",
            pricePerMonthString: "€4.99",
            pricePerYearString: "€59.88",
            productCategory: "SUBSCRIPTION",
            productType: "AUTO_RENEWABLE_SUBSCRIPTION",
            subscriptionPeriod: "P1M",
            defaultOption: {
              id: "android-blue-subscription-month-7-99",
              storeProductId:
                "android_blue_subscription_month_7.99:android-blue-subscription-month-7-99",
              productId: "android_blue_subscription_month_7.99",
              pricingPhases: [
                {
                  billingPeriod: {
                    unit: "MONTH",
                    value: 1,
                    iso8601: "P1M",
                  },
                  recurrenceMode: 1,
                  billingCycleCount: 0,
                  price: {
                    formatted: "€4.99",
                    amountMicros: 4990000,
                    currencyCode: "EUR",
                  },
                  offerPaymentMode: null,
                },
              ],
              tags: [],
              isBasePlan: true,
              billingPeriod: {
                unit: "MONTH",
                value: 1,
                iso8601: "P1M",
              },
              isPrepaid: false,
              fullPricePhase: {
                billingPeriod: {
                  unit: "MONTH",
                  value: 1,
                  iso8601: "P1M",
                },
                recurrenceMode: 1,
                billingCycleCount: 0,
                price: {
                  formatted: "€4.99",
                  amountMicros: 4990000,
                  currencyCode: "EUR",
                },
                offerPaymentMode: null,
              },
              freePhase: null,
              introPhase: null,
              presentedOfferingIdentifier: "blue-subscription",
              presentedOfferingContext: {
                offeringIdentifier: "blue-subscription",
                placementIdentifier: null,
                targetingContext: null,
              },
              installmentsInfo: null,
            },
            subscriptionOptions: [
              {
                id: "android-blue-subscription-month-7-99",
                storeProductId:
                  "android_blue_subscription_month_7.99:android-blue-subscription-month-7-99",
                productId: "android_blue_subscription_month_7.99",
                pricingPhases: [
                  {
                    billingPeriod: {
                      unit: "MONTH",
                      value: 1,
                      iso8601: "P1M",
                    },
                    recurrenceMode: 1,
                    billingCycleCount: 0,
                    price: {
                      formatted: "€4.99",
                      amountMicros: 4990000,
                      currencyCode: "EUR",
                    },
                    offerPaymentMode: null,
                  },
                ],
                tags: [],
                isBasePlan: true,
                billingPeriod: {
                  unit: "MONTH",
                  value: 1,
                  iso8601: "P1M",
                },
                isPrepaid: false,
                fullPricePhase: {
                  billingPeriod: {
                    unit: "MONTH",
                    value: 1,
                    iso8601: "P1M",
                  },
                  recurrenceMode: 1,
                  billingCycleCount: 0,
                  price: {
                    formatted: "€4.99",
                    amountMicros: 4990000,
                    currencyCode: "EUR",
                  },
                  offerPaymentMode: null,
                },
                freePhase: null,
                introPhase: null,
                presentedOfferingIdentifier: "blue-subscription",
                presentedOfferingContext: {
                  offeringIdentifier: "blue-subscription",
                  placementIdentifier: null,
                  targetingContext: null,
                },
                installmentsInfo: null,
              },
            ],
            presentedOfferingIdentifier: "blue-subscription",
            presentedOfferingContext: {
              offeringIdentifier: "blue-subscription",
              placementIdentifier: null,
              targetingContext: null,
            },
          },
          offeringIdentifier: "blue-subscription",
          presentedOfferingContext: {
            offeringIdentifier: "blue-subscription",
            placementIdentifier: null,
            targetingContext: null,
          },
        },
        {
          identifier: "$rc_annual",
          packageType: "ANNUAL",
          product: {
            identifier:
              "android_blue_subscription_year_80:android-blue-subscription-year-80",
            description: "Manage your aquarium with Blue",
            title: "Yearly subscription (Blue)",
            price: 54.99,
            priceString: "€54.99",
            currencyCode: "EUR",
            introPrice: null,
            discounts: null,
            pricePerWeek: 1054602,
            pricePerMonth: 4582500,
            pricePerYear: 54990000,
            pricePerWeekString: "€1.05",
            pricePerMonthString: "€4.58",
            pricePerYearString: "€54.99",
            productCategory: "SUBSCRIPTION",
            productType: "AUTO_RENEWABLE_SUBSCRIPTION",
            subscriptionPeriod: "P1Y",
            defaultOption: {
              id: "android-blue-subscription-year-80",
              storeProductId:
                "android_blue_subscription_year_80:android-blue-subscription-year-80",
              productId: "android_blue_subscription_year_80",
              pricingPhases: [
                {
                  billingPeriod: {
                    unit: "YEAR",
                    value: 1,
                    iso8601: "P1Y",
                  },
                  recurrenceMode: 1,
                  billingCycleCount: 0,
                  price: {
                    formatted: "€54.99",
                    amountMicros: 54990000,
                    currencyCode: "EUR",
                  },
                  offerPaymentMode: null,
                },
              ],
              tags: [],
              isBasePlan: true,
              billingPeriod: {
                unit: "YEAR",
                value: 1,
                iso8601: "P1Y",
              },
              isPrepaid: false,
              fullPricePhase: {
                billingPeriod: {
                  unit: "YEAR",
                  value: 1,
                  iso8601: "P1Y",
                },
                recurrenceMode: 1,
                billingCycleCount: 0,
                price: {
                  formatted: "€54.99",
                  amountMicros: 54990000,
                  currencyCode: "EUR",
                },
                offerPaymentMode: null,
              },
              freePhase: null,
              introPhase: null,
              presentedOfferingIdentifier: "blue-subscription",
              presentedOfferingContext: {
                offeringIdentifier: "blue-subscription",
                placementIdentifier: null,
                targetingContext: null,
              },
              installmentsInfo: null,
            },
            subscriptionOptions: [
              {
                id: "android-blue-subscription-year-80",
                storeProductId:
                  "android_blue_subscription_year_80:android-blue-subscription-year-80",
                productId: "android_blue_subscription_year_80",
                pricingPhases: [
                  {
                    billingPeriod: {
                      unit: "YEAR",
                      value: 1,
                      iso8601: "P1Y",
                    },
                    recurrenceMode: 1,
                    billingCycleCount: 0,
                    price: {
                      formatted: "€54.99",
                      amountMicros: 54990000,
                      currencyCode: "EUR",
                    },
                    offerPaymentMode: null,
                  },
                ],
                tags: [],
                isBasePlan: true,
                billingPeriod: {
                  unit: "YEAR",
                  value: 1,
                  iso8601: "P1Y",
                },
                isPrepaid: false,
                fullPricePhase: {
                  billingPeriod: {
                    unit: "YEAR",
                    value: 1,
                    iso8601: "P1Y",
                  },
                  recurrenceMode: 1,
                  billingCycleCount: 0,
                  price: {
                    formatted: "€54.99",
                    amountMicros: 54990000,
                    currencyCode: "EUR",
                  },
                  offerPaymentMode: null,
                },
                freePhase: null,
                introPhase: null,
                presentedOfferingIdentifier: "blue-subscription",
                presentedOfferingContext: {
                  offeringIdentifier: "blue-subscription",
                  placementIdentifier: null,
                  targetingContext: null,
                },
                installmentsInfo: null,
              },
            ],
            presentedOfferingIdentifier: "blue-subscription",
            presentedOfferingContext: {
              offeringIdentifier: "blue-subscription",
              placementIdentifier: null,
              targetingContext: null,
            },
          },
          offeringIdentifier: "blue-subscription",
          presentedOfferingContext: {
            offeringIdentifier: "blue-subscription",
            placementIdentifier: null,
            targetingContext: null,
          },
        },
      ],
      lifetime: null,
      annual: {
        identifier: "$rc_annual",
        packageType: "ANNUAL",
        product: {
          identifier:
            "android_blue_subscription_year_80:android-blue-subscription-year-80",
          description: "Manage your aquarium with Blue",
          title: "Yearly subscription (Blue)",
          price: 54.99,
          priceString: "€54.99",
          currencyCode: "EUR",
          introPrice: null,
          discounts: null,
          pricePerWeek: 1054602,
          pricePerMonth: 4582500,
          pricePerYear: 54990000,
          pricePerWeekString: "€1.05",
          pricePerMonthString: "€4.58",
          pricePerYearString: "€54.99",
          productCategory: "SUBSCRIPTION",
          productType: "AUTO_RENEWABLE_SUBSCRIPTION",
          subscriptionPeriod: "P1Y",
          defaultOption: {
            id: "android-blue-subscription-year-80",
            storeProductId:
              "android_blue_subscription_year_80:android-blue-subscription-year-80",
            productId: "android_blue_subscription_year_80",
            pricingPhases: [
              {
                billingPeriod: {
                  unit: "YEAR",
                  value: 1,
                  iso8601: "P1Y",
                },
                recurrenceMode: 1,
                billingCycleCount: 0,
                price: {
                  formatted: "€54.99",
                  amountMicros: 54990000,
                  currencyCode: "EUR",
                },
                offerPaymentMode: null,
              },
            ],
            tags: [],
            isBasePlan: true,
            billingPeriod: {
              unit: "YEAR",
              value: 1,
              iso8601: "P1Y",
            },
            isPrepaid: false,
            fullPricePhase: {
              billingPeriod: {
                unit: "YEAR",
                value: 1,
                iso8601: "P1Y",
              },
              recurrenceMode: 1,
              billingCycleCount: 0,
              price: {
                formatted: "€54.99",
                amountMicros: 54990000,
                currencyCode: "EUR",
              },
              offerPaymentMode: null,
            },
            freePhase: null,
            introPhase: null,
            presentedOfferingIdentifier: "blue-subscription",
            presentedOfferingContext: {
              offeringIdentifier: "blue-subscription",
              placementIdentifier: null,
              targetingContext: null,
            },
            installmentsInfo: null,
          },
          subscriptionOptions: [
            {
              id: "android-blue-subscription-year-80",
              storeProductId:
                "android_blue_subscription_year_80:android-blue-subscription-year-80",
              productId: "android_blue_subscription_year_80",
              pricingPhases: [
                {
                  billingPeriod: {
                    unit: "YEAR",
                    value: 1,
                    iso8601: "P1Y",
                  },
                  recurrenceMode: 1,
                  billingCycleCount: 0,
                  price: {
                    formatted: "€54.99",
                    amountMicros: 54990000,
                    currencyCode: "EUR",
                  },
                  offerPaymentMode: null,
                },
              ],
              tags: [],
              isBasePlan: true,
              billingPeriod: {
                unit: "YEAR",
                value: 1,
                iso8601: "P1Y",
              },
              isPrepaid: false,
              fullPricePhase: {
                billingPeriod: {
                  unit: "YEAR",
                  value: 1,
                  iso8601: "P1Y",
                },
                recurrenceMode: 1,
                billingCycleCount: 0,
                price: {
                  formatted: "€54.99",
                  amountMicros: 54990000,
                  currencyCode: "EUR",
                },
                offerPaymentMode: null,
              },
              freePhase: null,
              introPhase: null,
              presentedOfferingIdentifier: "blue-subscription",
              presentedOfferingContext: {
                offeringIdentifier: "blue-subscription",
                placementIdentifier: null,
                targetingContext: null,
              },
              installmentsInfo: null,
            },
          ],
          presentedOfferingIdentifier: "blue-subscription",
          presentedOfferingContext: {
            offeringIdentifier: "blue-subscription",
            placementIdentifier: null,
            targetingContext: null,
          },
        },
        offeringIdentifier: "blue-subscription",
        presentedOfferingContext: {
          offeringIdentifier: "blue-subscription",
          placementIdentifier: null,
          targetingContext: null,
        },
      },
      sixMonth: null,
      threeMonth: null,
      twoMonth: null,
      monthly: {
        identifier: "$rc_monthly",
        packageType: "MONTHLY",
        product: {
          identifier:
            "android_blue_subscription_month_7.99:android-blue-subscription-month-7-99",
          description: "Fully manage your aquarium with Blue",
          title: "Monthly subscription (Blue)",
          price: 4.99,
          priceString: "€4.99",
          currencyCode: "EUR",
          introPrice: null,
          discounts: null,
          pricePerWeek: 1148383,
          pricePerMonth: 4990000,
          pricePerYear: 59880000,
          pricePerWeekString: "€1.15",
          pricePerMonthString: "€4.99",
          pricePerYearString: "€59.88",
          productCategory: "SUBSCRIPTION",
          productType: "AUTO_RENEWABLE_SUBSCRIPTION",
          subscriptionPeriod: "P1M",
          defaultOption: {
            id: "android-blue-subscription-month-7-99",
            storeProductId:
              "android_blue_subscription_month_7.99:android-blue-subscription-month-7-99",
            productId: "android_blue_subscription_month_7.99",
            pricingPhases: [
              {
                billingPeriod: {
                  unit: "MONTH",
                  value: 1,
                  iso8601: "P1M",
                },
                recurrenceMode: 1,
                billingCycleCount: 0,
                price: {
                  formatted: "€4.99",
                  amountMicros: 4990000,
                  currencyCode: "EUR",
                },
                offerPaymentMode: null,
              },
            ],
            tags: [],
            isBasePlan: true,
            billingPeriod: {
              unit: "MONTH",
              value: 1,
              iso8601: "P1M",
            },
            isPrepaid: false,
            fullPricePhase: {
              billingPeriod: {
                unit: "MONTH",
                value: 1,
                iso8601: "P1M",
              },
              recurrenceMode: 1,
              billingCycleCount: 0,
              price: {
                formatted: "€4.99",
                amountMicros: 4990000,
                currencyCode: "EUR",
              },
              offerPaymentMode: null,
            },
            freePhase: null,
            introPhase: null,
            presentedOfferingIdentifier: "blue-subscription",
            presentedOfferingContext: {
              offeringIdentifier: "blue-subscription",
              placementIdentifier: null,
              targetingContext: null,
            },
            installmentsInfo: null,
          },
          subscriptionOptions: [
            {
              id: "android-blue-subscription-month-7-99",
              storeProductId:
                "android_blue_subscription_month_7.99:android-blue-subscription-month-7-99",
              productId: "android_blue_subscription_month_7.99",
              pricingPhases: [
                {
                  billingPeriod: {
                    unit: "MONTH",
                    value: 1,
                    iso8601: "P1M",
                  },
                  recurrenceMode: 1,
                  billingCycleCount: 0,
                  price: {
                    formatted: "€4.99",
                    amountMicros: 4990000,
                    currencyCode: "EUR",
                  },
                  offerPaymentMode: null,
                },
              ],
              tags: [],
              isBasePlan: true,
              billingPeriod: {
                unit: "MONTH",
                value: 1,
                iso8601: "P1M",
              },
              isPrepaid: false,
              fullPricePhase: {
                billingPeriod: {
                  unit: "MONTH",
                  value: 1,
                  iso8601: "P1M",
                },
                recurrenceMode: 1,
                billingCycleCount: 0,
                price: {
                  formatted: "€4.99",
                  amountMicros: 4990000,
                  currencyCode: "EUR",
                },
                offerPaymentMode: null,
              },
              freePhase: null,
              introPhase: null,
              presentedOfferingIdentifier: "blue-subscription",
              presentedOfferingContext: {
                offeringIdentifier: "blue-subscription",
                placementIdentifier: null,
                targetingContext: null,
              },
              installmentsInfo: null,
            },
          ],
          presentedOfferingIdentifier: "blue-subscription",
          presentedOfferingContext: {
            offeringIdentifier: "blue-subscription",
            placementIdentifier: null,
            targetingContext: null,
          },
        },
        offeringIdentifier: "blue-subscription",
        presentedOfferingContext: {
          offeringIdentifier: "blue-subscription",
          placementIdentifier: null,
          targetingContext: null,
        },
      },
      weekly: null,
    },
  },
  current: {
    identifier: "blue-subscription",
    serverDescription: "Subscription to blue premium",
    metadata: {},
    availablePackages: [
      {
        identifier: "$rc_monthly",
        packageType: "MONTHLY",
        product: {
          identifier:
            "android_blue_subscription_month_7.99:android-blue-subscription-month-7-99",
          description: "Fully manage your aquarium with Blue",
          title: "Monthly subscription (Blue)",
          price: 4.99,
          priceString: "€4.99",
          currencyCode: "EUR",
          introPrice: null,
          discounts: null,
          pricePerWeek: 1148383,
          pricePerMonth: 4990000,
          pricePerYear: 59880000,
          pricePerWeekString: "€1.15",
          pricePerMonthString: "€4.99",
          pricePerYearString: "€59.88",
          productCategory: "SUBSCRIPTION",
          productType: "AUTO_RENEWABLE_SUBSCRIPTION",
          subscriptionPeriod: "P1M",
          defaultOption: {
            id: "android-blue-subscription-month-7-99",
            storeProductId:
              "android_blue_subscription_month_7.99:android-blue-subscription-month-7-99",
            productId: "android_blue_subscription_month_7.99",
            pricingPhases: [
              {
                billingPeriod: {
                  unit: "MONTH",
                  value: 1,
                  iso8601: "P1M",
                },
                recurrenceMode: 1,
                billingCycleCount: 0,
                price: {
                  formatted: "€4.99",
                  amountMicros: 4990000,
                  currencyCode: "EUR",
                },
                offerPaymentMode: null,
              },
            ],
            tags: [],
            isBasePlan: true,
            billingPeriod: {
              unit: "MONTH",
              value: 1,
              iso8601: "P1M",
            },
            isPrepaid: false,
            fullPricePhase: {
              billingPeriod: {
                unit: "MONTH",
                value: 1,
                iso8601: "P1M",
              },
              recurrenceMode: 1,
              billingCycleCount: 0,
              price: {
                formatted: "€4.99",
                amountMicros: 4990000,
                currencyCode: "EUR",
              },
              offerPaymentMode: null,
            },
            freePhase: null,
            introPhase: null,
            presentedOfferingIdentifier: "blue-subscription",
            presentedOfferingContext: {
              offeringIdentifier: "blue-subscription",
              placementIdentifier: null,
              targetingContext: null,
            },
            installmentsInfo: null,
          },
          subscriptionOptions: [
            {
              id: "android-blue-subscription-month-7-99",
              storeProductId:
                "android_blue_subscription_month_7.99:android-blue-subscription-month-7-99",
              productId: "android_blue_subscription_month_7.99",
              pricingPhases: [
                {
                  billingPeriod: {
                    unit: "MONTH",
                    value: 1,
                    iso8601: "P1M",
                  },
                  recurrenceMode: 1,
                  billingCycleCount: 0,
                  price: {
                    formatted: "€4.99",
                    amountMicros: 4990000,
                    currencyCode: "EUR",
                  },
                  offerPaymentMode: null,
                },
              ],
              tags: [],
              isBasePlan: true,
              billingPeriod: {
                unit: "MONTH",
                value: 1,
                iso8601: "P1M",
              },
              isPrepaid: false,
              fullPricePhase: {
                billingPeriod: {
                  unit: "MONTH",
                  value: 1,
                  iso8601: "P1M",
                },
                recurrenceMode: 1,
                billingCycleCount: 0,
                price: {
                  formatted: "€4.99",
                  amountMicros: 4990000,
                  currencyCode: "EUR",
                },
                offerPaymentMode: null,
              },
              freePhase: null,
              introPhase: null,
              presentedOfferingIdentifier: "blue-subscription",
              presentedOfferingContext: {
                offeringIdentifier: "blue-subscription",
                placementIdentifier: null,
                targetingContext: null,
              },
              installmentsInfo: null,
            },
          ],
          presentedOfferingIdentifier: "blue-subscription",
          presentedOfferingContext: {
            offeringIdentifier: "blue-subscription",
            placementIdentifier: null,
            targetingContext: null,
          },
        },
        offeringIdentifier: "blue-subscription",
        presentedOfferingContext: {
          offeringIdentifier: "blue-subscription",
          placementIdentifier: null,
          targetingContext: null,
        },
      },
      {
        identifier: "$rc_annual",
        packageType: "ANNUAL",
        product: {
          identifier:
            "android_blue_subscription_year_80:android-blue-subscription-year-80",
          description: "Manage your aquarium with Blue",
          title: "Yearly subscription (Blue)",
          price: 54.99,
          priceString: "€54.99",
          currencyCode: "EUR",
          introPrice: null,
          discounts: null,
          pricePerWeek: 1054602,
          pricePerMonth: 4582500,
          pricePerYear: 54990000,
          pricePerWeekString: "€1.05",
          pricePerMonthString: "€4.58",
          pricePerYearString: "€54.99",
          productCategory: "SUBSCRIPTION",
          productType: "AUTO_RENEWABLE_SUBSCRIPTION",
          subscriptionPeriod: "P1Y",
          defaultOption: {
            id: "android-blue-subscription-year-80",
            storeProductId:
              "android_blue_subscription_year_80:android-blue-subscription-year-80",
            productId: "android_blue_subscription_year_80",
            pricingPhases: [
              {
                billingPeriod: {
                  unit: "YEAR",
                  value: 1,
                  iso8601: "P1Y",
                },
                recurrenceMode: 1,
                billingCycleCount: 0,
                price: {
                  formatted: "€54.99",
                  amountMicros: 54990000,
                  currencyCode: "EUR",
                },
                offerPaymentMode: null,
              },
            ],
            tags: [],
            isBasePlan: true,
            billingPeriod: {
              unit: "YEAR",
              value: 1,
              iso8601: "P1Y",
            },
            isPrepaid: false,
            fullPricePhase: {
              billingPeriod: {
                unit: "YEAR",
                value: 1,
                iso8601: "P1Y",
              },
              recurrenceMode: 1,
              billingCycleCount: 0,
              price: {
                formatted: "€54.99",
                amountMicros: 54990000,
                currencyCode: "EUR",
              },
              offerPaymentMode: null,
            },
            freePhase: null,
            introPhase: null,
            presentedOfferingIdentifier: "blue-subscription",
            presentedOfferingContext: {
              offeringIdentifier: "blue-subscription",
              placementIdentifier: null,
              targetingContext: null,
            },
            installmentsInfo: null,
          },
          subscriptionOptions: [
            {
              id: "android-blue-subscription-year-80",
              storeProductId:
                "android_blue_subscription_year_80:android-blue-subscription-year-80",
              productId: "android_blue_subscription_year_80",
              pricingPhases: [
                {
                  billingPeriod: {
                    unit: "YEAR",
                    value: 1,
                    iso8601: "P1Y",
                  },
                  recurrenceMode: 1,
                  billingCycleCount: 0,
                  price: {
                    formatted: "€54.99",
                    amountMicros: 54990000,
                    currencyCode: "EUR",
                  },
                  offerPaymentMode: null,
                },
              ],
              tags: [],
              isBasePlan: true,
              billingPeriod: {
                unit: "YEAR",
                value: 1,
                iso8601: "P1Y",
              },
              isPrepaid: false,
              fullPricePhase: {
                billingPeriod: {
                  unit: "YEAR",
                  value: 1,
                  iso8601: "P1Y",
                },
                recurrenceMode: 1,
                billingCycleCount: 0,
                price: {
                  formatted: "€54.99",
                  amountMicros: 54990000,
                  currencyCode: "EUR",
                },
                offerPaymentMode: null,
              },
              freePhase: null,
              introPhase: null,
              presentedOfferingIdentifier: "blue-subscription",
              presentedOfferingContext: {
                offeringIdentifier: "blue-subscription",
                placementIdentifier: null,
                targetingContext: null,
              },
              installmentsInfo: null,
            },
          ],
          presentedOfferingIdentifier: "blue-subscription",
          presentedOfferingContext: {
            offeringIdentifier: "blue-subscription",
            placementIdentifier: null,
            targetingContext: null,
          },
        },
        offeringIdentifier: "blue-subscription",
        presentedOfferingContext: {
          offeringIdentifier: "blue-subscription",
          placementIdentifier: null,
          targetingContext: null,
        },
      },
    ],
    lifetime: null,
    annual: {
      identifier: "$rc_annual",
      packageType: "ANNUAL",
      product: {
        identifier:
          "android_blue_subscription_year_80:android-blue-subscription-year-80",
        description: "Manage your aquarium with Blue",
        title: "Yearly subscription (Blue)",
        price: 54.99,
        priceString: "€54.99",
        currencyCode: "EUR",
        introPrice: null,
        discounts: null,
        pricePerWeek: 1054602,
        pricePerMonth: 4582500,
        pricePerYear: 54990000,
        pricePerWeekString: "€1.05",
        pricePerMonthString: "€4.58",
        pricePerYearString: "€54.99",
        productCategory: "SUBSCRIPTION",
        productType: "AUTO_RENEWABLE_SUBSCRIPTION",
        subscriptionPeriod: "P1Y",
        defaultOption: {
          id: "android-blue-subscription-year-80",
          storeProductId:
            "android_blue_subscription_year_80:android-blue-subscription-year-80",
          productId: "android_blue_subscription_year_80",
          pricingPhases: [
            {
              billingPeriod: {
                unit: "YEAR",
                value: 1,
                iso8601: "P1Y",
              },
              recurrenceMode: 1,
              billingCycleCount: 0,
              price: {
                formatted: "€54.99",
                amountMicros: 54990000,
                currencyCode: "EUR",
              },
              offerPaymentMode: null,
            },
          ],
          tags: [],
          isBasePlan: true,
          billingPeriod: {
            unit: "YEAR",
            value: 1,
            iso8601: "P1Y",
          },
          isPrepaid: false,
          fullPricePhase: {
            billingPeriod: {
              unit: "YEAR",
              value: 1,
              iso8601: "P1Y",
            },
            recurrenceMode: 1,
            billingCycleCount: 0,
            price: {
              formatted: "€54.99",
              amountMicros: 54990000,
              currencyCode: "EUR",
            },
            offerPaymentMode: null,
          },
          freePhase: null,
          introPhase: null,
          presentedOfferingIdentifier: "blue-subscription",
          presentedOfferingContext: {
            offeringIdentifier: "blue-subscription",
            placementIdentifier: null,
            targetingContext: null,
          },
          installmentsInfo: null,
        },
        subscriptionOptions: [
          {
            id: "android-blue-subscription-year-80",
            storeProductId:
              "android_blue_subscription_year_80:android-blue-subscription-year-80",
            productId: "android_blue_subscription_year_80",
            pricingPhases: [
              {
                billingPeriod: {
                  unit: "YEAR",
                  value: 1,
                  iso8601: "P1Y",
                },
                recurrenceMode: 1,
                billingCycleCount: 0,
                price: {
                  formatted: "€54.99",
                  amountMicros: 54990000,
                  currencyCode: "EUR",
                },
                offerPaymentMode: null,
              },
            ],
            tags: [],
            isBasePlan: true,
            billingPeriod: {
              unit: "YEAR",
              value: 1,
              iso8601: "P1Y",
            },
            isPrepaid: false,
            fullPricePhase: {
              billingPeriod: {
                unit: "YEAR",
                value: 1,
                iso8601: "P1Y",
              },
              recurrenceMode: 1,
              billingCycleCount: 0,
              price: {
                formatted: "€54.99",
                amountMicros: 54990000,
                currencyCode: "EUR",
              },
              offerPaymentMode: null,
            },
            freePhase: null,
            introPhase: null,
            presentedOfferingIdentifier: "blue-subscription",
            presentedOfferingContext: {
              offeringIdentifier: "blue-subscription",
              placementIdentifier: null,
              targetingContext: null,
            },
            installmentsInfo: null,
          },
        ],
        presentedOfferingIdentifier: "blue-subscription",
        presentedOfferingContext: {
          offeringIdentifier: "blue-subscription",
          placementIdentifier: null,
          targetingContext: null,
        },
      },
      offeringIdentifier: "blue-subscription",
      presentedOfferingContext: {
        offeringIdentifier: "blue-subscription",
        placementIdentifier: null,
        targetingContext: null,
      },
    },
    sixMonth: null,
    threeMonth: null,
    twoMonth: null,
    monthly: {
      identifier: "$rc_monthly",
      packageType: "MONTHLY",
      product: {
        identifier:
          "android_blue_subscription_month_7.99:android-blue-subscription-month-7-99",
        description: "Fully manage your aquarium with Blue",
        title: "Monthly subscription (Blue)",
        price: 4.99,
        priceString: "€4.99",
        currencyCode: "EUR",
        introPrice: null,
        discounts: null,
        pricePerWeek: 1148383,
        pricePerMonth: 4990000,
        pricePerYear: 59880000,
        pricePerWeekString: "€1.15",
        pricePerMonthString: "€4.99",
        pricePerYearString: "€59.88",
        productCategory: "SUBSCRIPTION",
        productType: "AUTO_RENEWABLE_SUBSCRIPTION",
        subscriptionPeriod: "P1M",
        defaultOption: {
          id: "android-blue-subscription-month-7-99",
          storeProductId:
            "android_blue_subscription_month_7.99:android-blue-subscription-month-7-99",
          productId: "android_blue_subscription_month_7.99",
          pricingPhases: [
            {
              billingPeriod: {
                unit: "MONTH",
                value: 1,
                iso8601: "P1M",
              },
              recurrenceMode: 1,
              billingCycleCount: 0,
              price: {
                formatted: "€4.99",
                amountMicros: 4990000,
                currencyCode: "EUR",
              },
              offerPaymentMode: null,
            },
          ],
          tags: [],
          isBasePlan: true,
          billingPeriod: {
            unit: "MONTH",
            value: 1,
            iso8601: "P1M",
          },
          isPrepaid: false,
          fullPricePhase: {
            billingPeriod: {
              unit: "MONTH",
              value: 1,
              iso8601: "P1M",
            },
            recurrenceMode: 1,
            billingCycleCount: 0,
            price: {
              formatted: "€4.99",
              amountMicros: 4990000,
              currencyCode: "EUR",
            },
            offerPaymentMode: null,
          },
          freePhase: null,
          introPhase: null,
          presentedOfferingIdentifier: "blue-subscription",
          presentedOfferingContext: {
            offeringIdentifier: "blue-subscription",
            placementIdentifier: null,
            targetingContext: null,
          },
          installmentsInfo: null,
        },
        subscriptionOptions: [
          {
            id: "android-blue-subscription-month-7-99",
            storeProductId:
              "android_blue_subscription_month_7.99:android-blue-subscription-month-7-99",
            productId: "android_blue_subscription_month_7.99",
            pricingPhases: [
              {
                billingPeriod: {
                  unit: "MONTH",
                  value: 1,
                  iso8601: "P1M",
                },
                recurrenceMode: 1,
                billingCycleCount: 0,
                price: {
                  formatted: "€4.99",
                  amountMicros: 4990000,
                  currencyCode: "EUR",
                },
                offerPaymentMode: null,
              },
            ],
            tags: [],
            isBasePlan: true,
            billingPeriod: {
              unit: "MONTH",
              value: 1,
              iso8601: "P1M",
            },
            isPrepaid: false,
            fullPricePhase: {
              billingPeriod: {
                unit: "MONTH",
                value: 1,
                iso8601: "P1M",
              },
              recurrenceMode: 1,
              billingCycleCount: 0,
              price: {
                formatted: "€4.99",
                amountMicros: 4990000,
                currencyCode: "EUR",
              },
              offerPaymentMode: null,
            },
            freePhase: null,
            introPhase: null,
            presentedOfferingIdentifier: "blue-subscription",
            presentedOfferingContext: {
              offeringIdentifier: "blue-subscription",
              placementIdentifier: null,
              targetingContext: null,
            },
            installmentsInfo: null,
          },
        ],
        presentedOfferingIdentifier: "blue-subscription",
        presentedOfferingContext: {
          offeringIdentifier: "blue-subscription",
          placementIdentifier: null,
          targetingContext: null,
        },
      },
      offeringIdentifier: "blue-subscription",
      presentedOfferingContext: {
        offeringIdentifier: "blue-subscription",
        placementIdentifier: null,
        targetingContext: null,
      },
    },
    weekly: null,
  },
};
